import { Section } from 'shared/components'
import styled from 'styled-components'

export const StyledSection = styled(Section)`
  margin: 0 auto;
  padding: ${p => p.theme.spacing(0, 3, 5)};
  max-width: 100%;

  & ol {
    list-style: decimal;
    margin-left: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(4)};
  }
  & ul {
    list-style: circle;
    margin-left: ${p => p.theme.spacing(2)};
  }

  & hr {
    margin: ${p => p.theme.spacing(3.5, 0)};
    background-color: ${p => p.theme.palette.text.light};
  }

  ${p => p.theme.breakpoints.up('md')} {
    padding: ${p => p.theme.spacing(0, 4, 10)};
  }
`
