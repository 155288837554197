import { Footer, Header } from 'sections'
import { Layout, RichText } from 'shared/components'
import { StyledSection } from './Article.style'

interface ArticleProps {
  pageContext: { content: string }
}

const Article = ({ pageContext }: ArticleProps) => {
  const { content } = pageContext
  return (
    <Layout topBar={<Header />} footer={<Footer />}>
      <StyledSection>
        <RichText content={content} typographyProps={{ align: 'left' }} />
      </StyledSection>
    </Layout>
  )
}

export default Article
